import * as _lie2 from "lie";

var _lie = "default" in _lie2 ? _lie2.default : _lie2;

import _combine from "./combine";
import * as _buffer2 from "buffer";

var _buffer = "default" in _buffer2 ? _buffer2.default : _buffer2;

var exports = {};
const Promise = _lie;
const combine = _combine;
const Buffer = _buffer.Buffer;
exports = binaryAjax;

function binaryAjax(_url, type) {
  return new Promise(function (resolve, reject) {
    const url = combine(_url, type);
    const ajax = new XMLHttpRequest();
    ajax.open("GET", url, true);

    if (type !== "prj" && type !== "cpg") {
      ajax.responseType = "arraybuffer";
    }

    ajax.addEventListener("load", function () {
      if (ajax.status > 399) {
        if (type === "prj" || type === "cpg") {
          return resolve(false);
        } else {
          return reject(new Error(ajax.status));
        }
      }

      if (type !== "prj" && type !== "cpg") {
        return resolve(Buffer.from(ajax.response));
      } else {
        return resolve(ajax.response);
      }
    }, false);
    ajax.send();
  });
}

export default exports;