import _binaryajaxBrowser from "./binaryajax-browser";
import _combine from "./combine";
import * as _buffer2 from "buffer";

var _buffer = "default" in _buffer2 ? _buffer2.default : _buffer2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
const fallback = _binaryajaxBrowser;
const combine = _combine;
const Buffer = _buffer.Buffer;

exports = async function binaryAjax(_url, type) {
  if (!_global.fetch) {
    return fallback(_url, type);
  }

  const url = combine(_url, type);
  const isOptionalTxt = type === "prj" || type === "cpg";

  try {
    const resp = await fetch(url);

    if (resp.status > 399) {
      throw new Error(resp.statusText);
    }

    if (isOptionalTxt) {
      return resp.text();
    }

    const parsed = await resp.arrayBuffer();
    return Buffer.from(parsed);
  } catch (e) {
    console.log("ERROR", e, type);

    if (isOptionalTxt || type === "dbf") {
      return false;
    }

    throw e;
  }
};

export default exports;